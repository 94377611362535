const socketService = {
  socket: null,
  init(username) {
    if (typeof WebSocket === "undefined") {
      alert("您的浏览器不支持socket");
    } else {
      let path = "ws://aihuman.stone007.cn/api/aihuman/ws/" + username; // 请求路径
      this.socket = new WebSocket(path);
      this.socket.onopen = this.open.bind(this);
      this.socket.onerror = this.error.bind(this);
      this.socket.onmessage = this.getMessage.bind(this);
    }
  },
  open() {
    localStorage.setItem('AILoading', 2)
    console.log("socket连接成功");
  },
  error() {
    console.log("连接错误");
  },
  getMessage(msg) {
    return new Promise((resolve, reject) => {
      this.socket.onmessage = (msg) => {
        // 利用promise 返回出去结果
        let obj = JSON.parse(msg.data)
        if (msg.data != '连接成功' && obj && obj.code == 99) {
          resolve(obj.data); // 将数据传递给调用者
        }
        // this.scrollInstance.refresh(); // 手动刷新滚动效果
      };
    });
    // this.scrollInstance.refresh(); // 手动刷新滚动效果
  },
  send(params) {
    if (this.socket) {
      this.socket.send(params);
    }
  },
  close() {
    console.log("socket已经关闭");
  }
};
//最后导出
export default socketService;