<template>
  <div>
    <div class="wrapper" v-loading="loading" element-loading-text="数据加载中">
      <div class="video" :style="{ opacity: opacity }">
        <video
          :id="id"
          style="width: 100vw; height: 100vh; object-fit: fill"
          preload="auto"
          :controls="false"
          playsinline
          autoplay="autoplay"
        >
          <source :src="liveUrl" type="application/x-mpegURL" />
        </video>
      </div>
      <div class="box">
        <div class="list" ref="scorllList">
          <div v-for="(item, index) in list" :key="index">
            <div class="item-box1" v-if="item.type == 1">
              <img
                class="box1-icon"
                src="https://sz-wonz-1305461842.cos.ap-beijing.myqcloud.com/system/health/yuyin91811.png"
              />
              <div class="box1-item">{{ item.text }}</div>
            </div>
            <div class="item-box2" v-else-if="item.type == 2">
              <div class="item2">{{ item.text }}</div>
              <img
                class="box2-icon"
                src="https://sz-wonz-1305461842.cos.ap-beijing.myqcloud.com/system/health/user987654.png"
              />
            </div>
            <div class="item-box3" v-else-if="item.type == 3">
              <div class="item3">肺康复训练</div>
              <div class="box3-line">
                <div class="box3-line-box1">
                  有用
                  <img
                    class="box3-icon"
                    src="https://sz-wonz-1305461842.cos.ap-beijing.myqcloud.com/system/health/zan91876238.png"
                  />
                </div>
                <div>
                  没用
                  <img
                    class="box3-icon"
                    src="https://sz-wonz-1305461842.cos.ap-beijing.myqcloud.com/system/health/cai198637111.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-box">
          <div class="bottom">
            <input
              type="text"
              placeholder="请输入您的问题..."
              class="bottom-input"
              cursor-spacing="34"
              confirm-type="send"
              v-model.trim="content"
            />
            <div class="icon-box" @click="send">发送</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
//路径是自己的啊
import socketService from "../../sokect/index.js";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  name: "DoctorAI",
  data() {
    return {
      opacity: 0,
      loading: false,
      timeout: "",
      loadVideoTime: "",
      db: null,
      options: {
        autoplay: false, // 设置自动播放
        muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: false, // 显示播放的控件
      },
      id: `videoPlayer${Date.now()}`,
      // url: "https://live.cgtn.com/1000/prog_index.m3u8", // 播放视频的路径' 测试地址
      // url:'https://pili-live-hls.live.xiangchaopai.com/zhuiyingtest/20240709150719700585.m3u8',

      screenWidth: document.body.clientWidth, //屏幕可视区宽度
      content: "",
      liveUrl: "",
      // liveUrl: "rtmp://liteavapp.qcloud.com/live/liteavdemoplayerstreamid",
      // liveUrl: "http://pull-demo.volcfcdnrd.com/live/st-4536523_yzmhde.flv",
      liveNo: "",
      list: [
        // 1:AI回答，2：用户发送，3肺康复训练
        {
          type: 1,
          text: "Hi，我是温致智能助手，有就医方面的问题都可以问我哦~",
        },
        // {
        //   type: 2,
        //   text: "2222",
        // },
      ],
    };
  },
  mounted() {
    // 使用 $nextTick 解决vedio报错  The element or ID supplied is not valid. (videojs)

    let that = this;
    let obj = this.$route.query;
    console.log("接受到的路由数据", obj);
    this.resetrem();
    // 监听窗口变化
    window.addEventListener("resize", function () {
      that.resetrem();
    });
    this.loading = true;
    localStorage.setItem("AILoading", 1);
    this.timeout = setInterval(() => {
      if (localStorage.getItem("AILoading") == 2) {
        localStorage.setItem("AILoading", 2);
        this.loading = false;
        clearInterval(this.timeout);
      }
    }, 500);
    // 调用
    this.startSocket();
  },

  beforeDestroy() {
    if (this.db) {
      this.db.dispose(); // dispose()会直接删除Dom元素
    }
  },
  watch: {
    // 窗口宽度改变
    screenWidth(n, o) {
      this.resetrem();
    },
  },
  methods: {
    async startSocket() {
      let that = this;
      // 这里是 username

      socketService.init(Date.now());
    },
    soket() {
      let client_id = Date.now();
      // document.querySelector("#ws-id").textContent = client_id;
      let ws = new WebSocket(
        `ws://aihuman.stone007.cn/api/aihuman/ws/${client_id}`
      );
      console.log(999199, ws);
      ws.onmessage = function (event) {
        console.log(1111, event);
        let messages = document.getElementById("messages");
        let message = document.createElement("li");
        let content = document.createTextNode(event.data);
        message.appendChild(content);
        messages.appendChild(message);
      };
      ws.send("什么是疾病");
      // function sendMessage(event) {
      //   let input = document.getElementById("messageText");
      //   ws.send(input.value);
      //   input.value = "";
      //   event.preventDefault();
      // }
    },
    loadVideo(url) {
      let that = this;
      this.db = videojs(this.id, this.options);
      // 也可以使用以下方式给vedio设置 src
      console.log("加载视频", this.db);
      this.db.src([
        {
          src: url, // 地址
          type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
        },
      ]);
      this.opacity = 1;
      this.db.errorDisplay.close();
    },

    resetrem() {
      //判断设备
      const isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      var html = document.querySelector("html"); //获取到html元素
      var width = html.getBoundingClientRect().width; //获取屏幕的宽度
      // if (width < 1400) {
      //   if (isMobile) {
      //     width = width * 2
      //   } else {
      //     width = 1400
      //   }
      // }
      html.style.fontSize = width / 100 + "px";
    },
    // startlive() {
    //   let that = this;
    //   var xhr = new XMLHttpRequest();
    //   xhr.open(
    //     "post",
    //     "https://aihuman.stone007.cn/api/aihuman/start_live",
    //     true
    //   );
    //   //3、设置 Content-Type属性（固定写法）
    //   xhr.setRequestHeader(
    //     "Content-Type",
    //     "application/x-www-form-urlencoded;"
    //   );

    //   xhr.onreadystatechange = (res) => {
    //     if (xhr.readyState == 4) {
    //       //监听readyState状态
    //       if (xhr.status == 200 || xhr.status == 0) {
    //         //监听HTTP状态码
    //         let obj = JSON.parse(xhr.responseText).data;
    //         console.log("startlive接收的数据", obj);
    //         that.liveNo = obj.liveNo;
    //         // that.liveUrl = obj.liveUrls.hlsUrl;
    //         // that.liveUrl = "https://live.cgtn.com/1000/prog_index.m3u8"  // 测试链接
    //         that.liveUrl = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8";
    //         that.$nextTick(() => {
    //           that.loadVideo(that.liveUrl);
    //         });
    //       }
    //     }
    //   };

    //   xhr.send({});
    // },
    send() {
      if (this.content) {
        this.list.push({
          type: 2,
          text: this.content,
        });
        setTimeout(() => {
          let container = this.$refs.scorllList;
          container.scrollTop = container.scrollHeight;
        }, 60);
        this.loading = true;
        this.submitText();
      }
    },
    async submitText() {
      let that = this;
      try {
        socketService.send(that.content);
      } catch (error) {
        console.error("Error receiving message:", error);
      }
      
      try {
        const obj = await socketService.getMessage();
        //打印出来 服务器给我的信息
        console.log("接收的消息", obj);
        that.list.push({
          type: 1,
          text: obj.message,
        });
        
        setTimeout(() => {
          let container = this.$refs.scorllList;
          container.scrollTop = container.scrollHeight;
        }, 60);
        that.loading = false;
        that.content = "";
        if (!that.liveUrl) {
          // that.liveUrl = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8'

          that.liveUrl = obj.liveUrls.hlsUrl;
          setTimeout(() => {
            that.$nextTick(() => {
              that.loadVideo(that.liveUrl);
              // 监听异常
              videojs(this.id).ready(function () {
                this.on("error", function () {
                  that.opacity = 0;
                  console.log("视频播放发生错误:", this.error());
                  if (that.loadVideoTime) {
                    clearTimeout(that.loadVideoTime);
                  }
                  that.loadVideoTime = setTimeout(() => {
                    that.loadVideo(that.liveUrl);
                    clearTimeout(that.loadVideoTime);
                  }, 1000);
                });
              });
            });
          }, 3000);
        }
      } catch (error) {
        console.error("Error receiving message:", error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/* 修改垂直滚动条 */
::-webkit-scrollbar {
  width: 0rem; /* 修改宽度 */
}

/* 修改滚动条轨道背景色 */
::-webkit-scrollbar-track {
  // background-color: #f1f1f1;
}

/* 修改滚动条滑块颜色 */
::-webkit-scrollbar-thumb {
  // background-color: #888;
}

/* 修改滚动条滑块悬停时的颜色 */
::-webkit-scrollbar-thumb:hover {
  // background-color: #555;
}
.wrapper {
  position: relative;
  width: 100rem;
  height: 100vh;
  overflow: hidden;
}

.video {
  width: 500px;
  height: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  object-fit: fill;
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100rem;
  height: 100vh;

  .list {
    position: absolute;
    top: 0rem;
    bottom: 26.7rem;
    left: 0rem;
    right: 0rem;
    overflow-y: scroll;
    overflow-x: hidden;
    // display: flex;
    // justify-content: flex-end;
    // align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0rem 0.67rem;
    padding-top: 2rem;
    font-weight: 400;
    font-size: 3.7rem;
    color: #ffffff;

    .item-box1 {
      height: auto;
      width: 73.3rem;
      padding: 3.7rem 4.8rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border-radius: 5.3rem;
      background: #00000066;
      margin-bottom: 2.1rem;
      margin-left: 2.4rem;

      .box1-icon {
        width: 4rem;
        height: 4rem;
        margin-top: 0.4rem;
        margin-right: 0.8rem;
      }

      .box1-item {
        line-height: 5.3rem;
        text-align: left;
        word-wrap: break-word;
        word-break: break-all;
        font-weight: 400;
        font-size: 3.7rem;
        color: #ffffff;
        max-width: 68rem;
      }
    }

    .item-box2 {
      height: auto;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 2.1rem;

      .item2 {
        height: auto;
        max-width: 66.7rem;
        padding: 3.7rem 4.8rem;
        margin-right: 2.4rem;
        background: #1866ffdd;
        font-weight: 400;
        font-size: 3.7rem;
        color: #ffffff;
        line-height: 5.3rem;
        border-radius: 5.3rem;

        word-wrap: break-word;
        word-break: break-all;
      }

      .box2-icon {
        width: 10.7rem;
        height: 10.7rem;
        border-radius: 50%;
      }
    }

    .item-box3 {
      height: auto;
      width: 93.3rem;
      padding: 3.7rem 4.8rem;
      margin: auto;
      // display: flex;
      // align-items: flex-start;
      // justify-content: flex-start;
      border-radius: 5.3rem;
      background: #00000066;
      margin-bottom: 2.1rem;
      margin-left: 2.4rem;
      margin-right: 2.4rem;

      .item3 {
        width: 100%;
        height: 15.5rem;
        background: #ffffff;
        border-radius: 4rem;
        font-weight: 500;
        font-size: 4.3rem;
        color: #1d6aff;
        line-height: 5.3rem;
        padding: 5.1rem 6.9rem;
        box-sizing: border-box;
      }

      .box3-line {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-weight: 400;
        font-size: 3.2rem;
        color: #ffffff;
        margin-top: 2.7rem;
        .box3-line-box1 {
          margin-right: 5.3rem;
        }
        .box3-icon {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 1.3rem;
        }
      }
    }
  }

  .bottom-box {
    .bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      border-radius: 3.2rem 3.2rem 0rem 0rem;
      width: 100rem;
      height: 22rem;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 5.1rem;
      box-sizing: border-box;

      .bottom-input {
        width: 73.1rem;
        height: 10.7rem;
        background: rgba(0, 0, 0, 0.04);
        border: none;
        border-radius: 5.3rem;
        margin-right: 3.5rem;
        font-weight: 400;
        font-size: 4rem;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0rem 4.3rem;
        box-sizing: border-box;
        border: none;
        outline: none;
      }

      .icon-box {
        font-size: 3.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10.9rem;
        width: 14.7rem;
        height: 10.9rem;
        background: #1e69fb;
        color: #fff;

        .bottom-icon {
          width: 4rem;
          height: 5.3rem;
        }
      }
    }
  }
}
</style>